

#banner {

  // .carousel {
  //   margin-top: -31px;
  //   height: 21em;
  //   width: 100%;
  //   min-height: 230px;
  //   position: relative;
  //   background-color: #2f81ed;
  //   background-image: linear-gradient(to right, #2f80ed, #56ccf2);
  //   @media (max-width: 767px) {
  //     display: none;
  //   }
  //   .carousel-inner {
  //     height: 100%;
  //     display: none;
  //   }
  // }
  // .banner-heading {
  //   max-width: 850px;
  //   margin: auto;
  //   padding: 40px 50px;
  // }
  img {
    height: 52em;
    width: 100%;
    min-height: 495px;
    object-position: center;
    object-fit: cover;
  }
}
.bn-Img{
  // background: url("../../assets/images/bg/bg.jpg");
  background: url("https://dev-oc-images.s3.eu-north-1.amazonaws.com/images/bg/bg.jpg");
}

.gradient-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  // background: radial-gradient(circle, rgba(0, 0, 0, 0.5) 100%, rgba(0, 0, 0, 0.3) 0%,  rgba(0, 0, 0, 0) 0%);

  // background: linear-gradient(to bottom, rgba(0, 0, 0, 0.5) 0%, rgba(0, 0, 0, 0.3) 50%, rgba(0, 0, 0, 0) 100%);
  pointer-events: none;
  /* Allows interaction with the underlying elements */
  // background: linear-gradient(270deg,rgba(5,16,54,.5),#051036 72.43%);
  opacity: .80;
}

.fh-main-cc2 {
  height: 650px;
  overflow: hidden;
  @media (max-width: 770px) {
    display: block !important;
  }

  @media (max-width: 767px) {
    display: none !important;
  }
}

// @media screen and (max-width: 768px) {
//   .fh-main-cc2 {
//     display: none;
//   }
// }