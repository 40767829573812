.currency-flag-img {
  max-width: 15px;
  max-height: 12px;
  margin-right: 10px;
}

.selectedNav {
  color: #2547a3 !important;
  font-weight: 600;
  // display: flex;
}

.nav-wallet-wrapper {
  padding: 5px 12px;

  .anticon {
    font-size: 16px;
    font-family: "Nunito";
  }
}

.user-btn-wrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;

  .anticon {
    margin: 0 5px;
  }
}

.header-bg {
  background-color: #00164d;
}

.header-containertrans-nor {
  background: white;

  position: relative;
  // z-index: 70;
  width: 100%;
  padding-bottom: 5px;
  border-bottom: none;

  @media (max-width: 770px) {
    height: 65px;
  }

  @media (max-width: 767px) {
    background-color: transparent;
    box-shadow: none;
    z-index: 4;
  }

  .wrapper {
    max-width: 1240px;
    background-color: transparent;
    margin: 0 auto;
    padding-left: 15px;
    padding-right: 15px;

    .header {
      width: 100%;
      background-color: transparent;
      display: flex;
      padding-bottom: 3px;
      max-height: 50px;


      .app-logo {
        .company-logo {
          height: 100%;
          display: flex;
          align-items: center;
          justify-content: flex-end;
          padding: 0px 0;
          margin-top: 8px;

          //max-width: 85px;
          @media (max-width: 770px) {
            // background-image: linear-gradient(to right, #2f80ed, #56ccf2);
            background-color: transparent;
            justify-content: flex-start;

          }

          @media (max-width: 767px) {
            justify-content: start;
            max-width: 80px;
            background-image: linear-gradient(to right, #2f81ed00, #56cbf200);
          }

          @media (max-width: 567px) {
            max-width: 70px;
          }

          @media (max-width: 480px) {
            justify-content: space-evenly;
          }

          img {
            height: 50px;
            width: auto;
            margin-top: 0px;
            margin-bottom: 7px;

            @media (max-width: 480px) {
              height: 30px;
              width: auto;
            }
          }
        }

        @media (max-width: 767px) {
          // width: initial;
          display: flex;
          justify-content: space-between;
          align-items: center;
          margin: 0;
        }
      }


      .app-links {
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding-left: 12px;

        @media (max-width: 991px) {
          display: block;
        }

        @media (max-width: 767px) {
          align-items: flex-start;
        }

        .menu1 {
          // border: 2px solid red !important;
          width: 30%;
          // border-left: 1px solid rgb(176, 176, 176);

          @media (max-width: 991px) {
            width: 35%;

            position: relative;
            top: 4%;
            right: 5%;
            border: none;
          }

          @media (max-width: 767px) {
            display: block;
          }

          .currency-text {
            .ant-select:not(.ant-select-customize-input) .ant-select-selector {
              background-color: transparent !important;
            }
          }

          .goog-te-combo {
            background-color: transparent !important;
            border: none !important;
            color: white !important;
          }

          li {

            display: flex;
            align-items: center;
            height: 100%;
            border-right: 0px solid #c7c7c7;
            padding: 5px 0px 0px 10px;
            font-size: 14px;
            font-family: "Nunito";
            margin-top: 4px;
            width: 50%;

            @media (max-width: 991px) {
              width: 44%;
            }

            @media (max-width: 767px) {
              padding: 4px 0;
              border-right: 0px solid #c7c7c7;

            }
          }

        }

        ul {
          display: flex;
          justify-content: space-around;

          padding: 0;
          list-style: none;
          font-weight: 700;
          margin-left: auto;


          @media (max-width: 991px) {
            width: 54%;
          }

          @media (max-width: 767px) {
            display: block;
          }

          li {
            padding: 15px;
            display: flex;
            align-items: center;
            height: 100%;
            border-right: 0px solid #c7c7c7;
            padding: 0px 5px 0px 50px;
            font-size: 14px;
            font-family: "Nunito";
            width: 18%;
            justify-content: space-evenly;

            @media (max-width: 767px) {
              padding: 4px 0;
              border-right: 0px solid #c7c7c7;
              width: 100%;
              text-align: left;
              justify-content: left;

            }

            a {
              color: #444444;
              align-items: center;
              text-decoration: none;
              font-size: 16px;
              font-family: "Nunito";
              font-weight: 700;
              //border: 1px solid grey;
              border-radius: 6px;
              padding: 1px 10px;



              @media (max-width: 767px) {
                color: #000000;
                display: flex;
                box-shadow: none;
                border: none;
                border-radius: 0px;
                padding: 0;
              }

              .anticon {
                margin-left: 8px;
              }
            }

            span {
              font-size: 16px;
              font-weight: 800;
              //color: #ffffff;
              font-family: 'Nunito';

              @media (max-width: 767px) {
                color: #ffffff;
              }
            }
          }

          .custom-drop-down {
            position: initial;

            a {
              color: #fff;
            }

            .dropdown-item {
              color: #ffffff !important;
            }
          }
        }

        @media screen and (max-width: 768px) {
          flex-flow: column;
          width: 100%;
          text-align: left;
        }

        @media (max-width: 991px) {
          display: flex;
          flex-flow: inherit;
          margin-left: -4%;
        }

        @media (max-width: 770px) {

          margin-left: 7%;
          margin-top: -15.5%;
        }

        @media (max-width: 767px) {
          margin-left: -14px;
          margin-top: -3%;

          background-color: white;
          width: 108%;
          padding-top: 8%;
        }
      }


      .app-links1 {
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: end;
        padding-left: 12px;

        @media (max-width: 991px) {
          display: block;
        }

        @media (max-width: 767px) {
          align-items: flex-start;

        }

        .menu1 {
          // border: 2px solid red !important;
          width: 30%;
          align-items: center;
          // border-left: 1px solid rgb(176, 176, 176);

          @media (max-width: 991px) {
            width: 35%;
            // border: none;
            position: relative;
            top: 4%;
            right: 9%;
            border: none;
          }

          @media (max-width: 767px) {
            display: block;
          }

          .currency-text {
            .ant-select:not(.ant-select-customize-input) .ant-select-selector {
              background-color: transparent !important;
            }
          }

          .goog-te-combo {
            background-color: transparent !important;
            border: none !important;
            color: white !important;
          }

          li {
            // border-left: 2px solid #2547a3;
            display: flex;
            align-items: center;
            height: 100%;
            border-right: 0px solid #c7c7c7;
            padding: 0px 0px 0px 0px;
            font-size: 14px;
            font-family: "Nunito";
            width: 33%;
            //border: 1px solid #09aedb;
            border-radius: 18px;

            @media (max-width: 991px) {
              width: 44%;
            }

            @media (max-width: 767px) {
              padding: 4px 0;
              border-right: 0px solid #c7c7c7;

            }
          }

        }

        ul {
          display: flex;
          justify-content: space-around;

          padding: 0;
          list-style: none;
          font-weight: 700;
          margin-left: auto;
          // width: 60%;
          // width: 30%;

          @media (max-width: 991px) {
            width: 54%;
          }

          @media (max-width: 767px) {
            display: block;
          }

          li {
            padding: 15px;
            display: flex;
            align-items: center;
            height: 100%;
            border-right: 0px solid #c7c7c7;
            padding: 0px 5px 0px 50px;
            font-size: 14px;
            font-family: "Nunito";
            width: 18%;
            justify-content: space-evenly;

            @media (max-width: 767px) {
              padding: 4px 0;
              border-right: 0px solid #c7c7c7;
              width: 100%;
              text-align: left;
              justify-content: left;

            }

            a {
              color: #060606;
              align-items: center;
              text-decoration: none;
              font-size: 18px;
              font-weight: 700;
              font-family: 'Nunito';
              padding: 1px 10px;


              &:hover {
                // color: #35459c;
                // box-shadow: 1px 2px 4px #35459c;
              }

              @media (max-width: 767px) {
                color: #000000;
                display: flex;
                box-shadow: none;
                border: none;
                border-radius: 0px;
                padding: 0;
              }

              .anticon {
                margin-left: 8px;
              }
            }

            span {
              font-size: 16px;
              font-weight: 800;
              font-family: 'Nunito';
              // height: 32px;


              @media (max-width: 767px) {
                color: #ffffff;
              }
            }
          }

          .custom-drop-down {
            position: initial;

            a {
              color: #fff;
            }

            .dropdown-item {
              color: #ffffff !important;
            }
          }
        }

        @media screen and (max-width: 768px) {
          flex-flow: column;
          width: 100%;
          text-align: left;
          background-color: rgba(137, 0, 0, 0);
        }

        @media (max-width: 991px) {
          display: flex;
          flex-flow: inherit;
          position: absolute;
          top: 96%;
          background-color: white;
          left: 0%;
          padding-bottom: 1%;
        }

        @media (max-width: 770px) {

          margin-left: 0%;
          margin-top: 1.5%;
          padding-bottom: 1%;
        }

        @media (max-width: 767px) {
          margin-left: 0px;
          margin-top: 0%;

          background-color: rgba(255, 255, 255, 0);
          width: 108%;


        }
      }

    }

    .header li a:hover,
    .header .menu-btn:hover {
      color: #bcd5f5;
      text-decoration: none !important;
      outline: none !important;
    }

    /* menu */
    .header .menu {
      max-height: 0;
      transition: max-height 0.2s ease-out;
    }

    /* menu icon */
    .header {
      .menu-icon {
        cursor: pointer;
        display: inline-block;
        padding: 28px 20px;
        position: relative;
        user-select: none;

        @media (max-width: 767px) {
          padding: 28px 0 28px 20px;
        }

        .navicon {
          background: #000000;
          display: block;
          height: 1px;
          position: relative;
          transition: background 0.2s ease-out;
          width: 18px;

          @media (max-width: 991px) {
            background: transparent;
          }

          @media (max-width: 770px) {
            background: transparent;
          }

          @media (max-width: 767px) {
            background: #bd0c21;
          }
        }
      }

      .user-icon {
        display: flex;
        align-items: center;

        i {
          margin-right: 20px;
          font-size: 20px;
          font-family: "Nunito";
          color: #fff;
        }

        .menu-icon {
          margin-bottom: 0;
          padding: 22px 2px 28px 2px;
          margin-left: 18px;
        }

        .menu-text {
          color: #000000;
          font-size: 10px;
          font-family: "Nunito";
          position: absolute;
          top: 30px;
          left: -2px;
        }
      }
    }

    .header .menu-icon .navicon:before,
    .header .menu-icon .navicon:after {
      background: #000000;
      content: "";
      display: block;
      height: 100%;
      position: absolute;
      transition: all 0.2s ease-out;
      width: 100%;

      @media (max-width: 991px) {
        background: transparent;
      }

      @media (max-width: 770px) {
        background: transparent;
      }

      @media (max-width: 767px) {
        background: #bd0c21;
      }
    }

    .header .menu-icon .navicon:before {
      top: 5px;
    }

    .header .menu-icon .navicon:after {
      top: -5px;
    }

    /* menu btn */
    .header .menu-btn {
      display: none;
    }

    .header .menu-btn:checked~.menu {
      max-height: 430px;
    }

    .header .menu-btn:checked~.menu-icon .navicon {
      background: transparent;
    }

    .header .menu-btn:checked~.menu-icon .navicon:before {
      transform: rotate(-45deg);
    }

    .header .menu-btn:checked~.menu-icon .navicon:after {
      transform: rotate(45deg);
    }

    .header .menu-btn:checked~.menu-icon:not(.steps) .navicon:before,
    .header .menu-btn:checked~.menu-icon:not(.steps) .navicon:after {
      top: 0;
    }

    .iconText {
      margin-left: 5px;
    }

    .service-links {
      align-items: center;

      .nav-link {
        color: white !important;
        font-size: 16px;
        font-family: "Nunito";

        i {
          margin-left: 5px;
          font-size: 15px;
          font-family: "Nunito";
        }
      }
    }

    @media (min-width: 992px) {

      // .header li {
      //   // float: left;
      // }
      // .header li a {
      //   // padding: 23px 30px;
      // }
      .header .menu {
        max-height: none !important;
      }

      .header .menu-icon {
        display: none;
      }
    }

    .header {
      @media (max-width: 770px) {
        padding: 0 0px;
        display: block;
        position: fixed;
        top: 0;
        left: 0;
        right: 0;
        // background: #00164d;
        background-color: white;
        // background-image: linear-gradient(to right, #2f80ed, #56ccf2);
        border-bottom: 1px solid #bcbcbc;

        a {
          img {
            margin-top: 5px;
          }
        }
      }
    }

    @media screen and (max-width: 767px) {
      .header {
        padding: 0 15px;
        display: block;
        position: fixed;
        top: 0;
        left: 0;
        right: 0;
        // background: #00164d;
        background-color: white;
        // background-image: linear-gradient(to right, #2f80ed, #56ccf2);
        border-bottom: 1px solid #bcbcbc;


        a {
          img {
            margin-top: 5px;
          }
        }
      }
    }

    @media (max-width: 480px) {
      .header {
        padding: 0 6px;
        display: block;
        position: fixed;
        top: 0;
        left: -10px;
        right: 0;
        // background: #00164d;
        background-color: white;
        // background-image: linear-gradient(to right, #2f80ed, #56ccf2);
        border-bottom: 1px solid #bcbcbc;


        a {
          img {
            margin-top: 5px;
          }
        }
      }
    }
  }

}

.header-container {
  background: #ffffff;
  // box-shadow: 1px 1px 4px 0 rgba(0, 0, 0, 0.1);
  position: fixed;
  z-index: 70;
  width: 100%;
  padding-bottom: 5px;
  border-bottom: none;

  @media (max-width: 770px) {
    height: 65px;
  }

  @media (max-width: 767px) {
    background-color: transparent;
    box-shadow: none;

  }

  .wrapper {
    max-width: 1240px;
    background-color: transparent;
    margin: 0 auto;
    padding-left: 15px;
    padding-right: 15px;

    .header {
      width: 100%;
      background-color: transparent;
      display: flex;
      padding-bottom: 3px;
      max-height: 50px;


      .app-logo {
        .company-logo {
          height: 70px;
          display: flex;
          align-items: center;
          justify-content: flex-end;
          padding: 0px 0;


          @media (max-width: 770px) {

            background-color: transparent;
            justify-content: flex-start;

          }

          @media (max-width: 767px) {
            justify-content: start;
            max-width: 80px;
            background-image: linear-gradient(to right, #2f81ed00, #56cbf200);
          }

          @media (max-width: 567px) {
            max-width: 70px;
          }

          img {
            height: 50px;
            width: auto;
            margin-top: 0px;
            margin-bottom: 2px;
          }
        }

        // @media (max-width: 767px) {
        //   // width: initial;
        //   display: flex;
        //   justify-content: space-between;
        //   align-items: center;
        //   margin: 0;
        // }
      }
      .app-logo {
        .company-logo {
          height: 100%;
          display: flex;
          align-items: center;
          justify-content: flex-end;
          padding: 0px 0;

          //max-width: 85px;
          @media (max-width: 770px) {
            // background-image: linear-gradient(to right, #2f80ed, #56ccf2);
            background-color: transparent;
            justify-content: flex-start;

          }

          @media (max-width: 767px) {
            justify-content: start;
            max-width: 80px;
            background-image: linear-gradient(to right, #2f81ed00, #56cbf200);
          }

          @media (max-width: 567px) {
            max-width: 70px;
          }

          @media (max-width: 480px) {
            justify-content: space-evenly;
          }

          img {
            height: 50px;
            width: auto;
            margin-top: -3px;
            margin-bottom: 7px;

            @media (max-width: 480px) {
              height: 30px;
              width: auto;
            }
          }
        }

        @media (max-width: 767px) {
          // width: initial;
          display: flex;
          justify-content: space-between;
          align-items: center;
          margin: 0;
        }
      }


      .app-links {
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding-left: 12px;

        @media (max-width: 991px) {
          display: block;
        }

        @media (max-width: 767px) {
          align-items: flex-start;
        }

        .menu1 {
          // border: 2px solid red !important;
          width: 30%;
          // border-left: 1px solid rgb(176, 176, 176);

          @media (max-width: 991px) {
            width: 35%;
            // border: none;
            position: relative;
            top: 4%;
            right: 5%;
            border: none;
          }

          @media (max-width: 767px) {
            display: block;
          }

          .currency-text {
            .ant-select:not(.ant-select-customize-input) .ant-select-selector {
              background-color: transparent !important;
            }
          }

          .goog-te-combo {
            background-color: transparent !important;
            border: none !important;
            color: white !important;
          }

          li {
            // padding: 15px;
            display: flex;
            align-items: center;
            height: 100%;
            border-right: 0px solid #c7c7c7;
            padding: 5px 0px 0px 10px;
            font-size: 14px;
            font-family: "Nunito";
            margin-top: 4px;
            width: 50%;

            @media (max-width: 991px) {
              width: 44%;
            }

            @media (max-width: 767px) {
              padding: 4px 0;
              border-right: 0px solid #c7c7c7;

            }
          }

        }

        ul {
          display: flex;
          justify-content: space-around;

          padding: 0;
          list-style: none;
          font-weight: 700;
          // overflow: hidden;
          margin-left: auto;
          // width: 60%;
          // width: 30%;

          @media (max-width: 991px) {
            width: 54%;
          }

          @media (max-width: 767px) {
            display: block;
          }

          li {
            padding: 15px;
            display: flex;
            align-items: center;
            height: 100%;
            border-right: 0px solid #c7c7c7;
            padding: 0px 5px 0px 50px;
            font-size: 14px;
            font-family: "Nunito";
            width: 18%;
            justify-content: space-evenly;

            @media (max-width: 767px) {
              padding: 4px 0;
              border-right: 0px solid #c7c7c7;
              width: 100%;
              text-align: left;
              justify-content: left;

            }

            a {
              color: #444444;
              align-items: center;
              text-decoration: none;
              font-size: 16px;
              font-family: "Nunito";
              padding: 1px 10px;

              // border-radius: 4px;
              &:hover {
                // color: #35459c;
                // box-shadow: 1px 2px 4px #35459c;
              }

              @media (max-width: 767px) {
                color: #000000;
                display: flex;
                box-shadow: none;
                border: none;
                border-radius: 0px;
                padding: 0;
              }

              .anticon {
                margin-left: 8px;
              }
            }

            span {
              font-size: 14px;
              font-family: "Nunito";
              color: #000000;

              @media (max-width: 767px) {
                color: #ffffff;
              }
            }
          }

          .custom-drop-down {
            position: initial;

            a {
              color: #fff;
            }

            .dropdown-item {
              color: #ffffff !important;
            }
          }
        }

        @media screen and (max-width: 768px) {
          flex-flow: column;
          width: 100%;
          text-align: left;
        }

        @media (max-width: 991px) {
          display: flex;
          flex-flow: inherit;
          margin-left: -4%;
        }

        @media (max-width: 770px) {

          margin-left: 7%;
          margin-top: -15.5%;
        }

        @media (max-width: 767px) {
          margin-left: -14px;
          margin-top: -3%;

          background-color: white;
          width: 108%;
          padding-top: 8%;
        }
      }


      .app-links1 {
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding-left: 12px;

        @media (max-width: 991px) {
          display: block;
        }

        @media (max-width: 767px) {
          align-items: flex-start;
          background: linear-gradient(45deg, #ffffff, #bad8fc);

        }

        .menu1 {

          width: 30%;
          align-items: center;


          @media (max-width: 991px) {
            width: 35%;

            position: relative;
            top: 4%;
            right: 9%;
            border: none;
          }

          @media (max-width: 767px) {
            display: block;
          }

          .currency-text {
            .ant-select:not(.ant-select-customize-input) .ant-select-selector {
              background-color: transparent !important;
            }
          }

          .goog-te-combo {
            background-color: transparent !important;
            border: none !important;
            color: white !important;
          }

          li {

            display: flex;
            align-items: center;
            height: 100%;
            // border-right: 0px solid #c7c7c7;
            padding: 0px 0px 0px 0px;
            font-family: "Nunito";
            font-size: 14px;
            width: 33%;
            //border: 1px solid #09aedb;
            border-radius: 18px;

            @media (max-width: 991px) {
              width: 44%;
            }

            @media (max-width: 767px) {
              padding: 4px 0;
              border-right: 0px solid #c7c7c7;

            }
          }

        }

        ul {
          display: flex;
          justify-content: space-around;

          padding: 0;
          list-style: none;
          font-weight: 700;
          margin-left: auto;


          @media (max-width: 991px) {
            width: 54%;
          }

          @media (max-width: 767px) {
            display: block;
          }

          li {
            // border-left: 2px solid #2547a3;
            padding: 2px 15px;
            display: flex;
            align-items: center;
            height: 100%;
            border-right: 0px solid #c7c7c7;
            padding: 0px 5px 0px 50px;
            font-size: 14px;
            font-family: "Nunito";
            width: 18%;
            justify-content: space-evenly;

            @media (max-width: 767px) {
              padding: 4px 0;
              border-right: 0px solid #c7c7c7;
              width: 100%;
              text-align: left;
              justify-content: left;

            }

            a {
              color: #444444;
              align-items: center;
              text-decoration: none;
              font-size: 18px;
              // box-shadow: 1px 1px 2px #bd0c21;
              // border: 1px solid grey;
              // border-radius: 6px;
              padding: 1px 10px;
              font-family: 'Nunito';
              font-weight: 700;

              // border-radius: 4px;
              &:hover {
                color: #35459c;
                box-shadow: 1px 2px 4px #35459c;
              }

              @media (max-width: 767px) {
                color: #000000;
                display: flex;
                box-shadow: none;
                border: none;
                border-radius: 0px;
                padding: 0;
              }

              .anticon {
                margin-left: 8px;
              }
            }

            span {
              font-size: 16px;
              color: #000000;
              font-weight: 800;
              font-family: 'Nunito';
              //height: 32px;

              @media (max-width: 767px) {
                color: #ffffff;
              }
            }
          }

          .custom-drop-down {
            position: initial;

            a {
              color: #fff;
            }

            .dropdown-item {
              color: #ffffff !important;
            }
          }
        }

        @media screen and (max-width: 768px) {
          flex-flow: column;
          width: 100%;
          text-align: left;
        }

        @media (max-width: 991px) {
          display: flex;
          flex-flow: inherit;
          position: absolute;
          top: 96%;
          background-color: white;
          left: 0%;
          padding-bottom: 1%;
        }

        @media (max-width: 770px) {

          margin-left: 0%;
          margin-top: 1.5%;
          padding-bottom: 1%;
        }

        @media (max-width: 767px) {
          margin-left: 0px;
          margin-top: 0%;
          // background-image: linear-gradient(to right, #bd0c21de, #35459ccc);
          background-color: white;
          width: 108%;


        }
      }

    }

    .header li a:hover,
    .header .menu-btn:hover {
      color: #bcd5f5;
      text-decoration: none !important;
      outline: none !important;
    }

    /* menu */
    .header .menu {
      max-height: 0;
      transition: max-height 0.2s ease-out;
    }

    /* menu icon */
    .header {
      .menu-icon {
        cursor: pointer;
        display: inline-block;
        padding: 28px 20px;
        position: relative;
        user-select: none;

        @media (max-width: 767px) {
          padding: 28px 0 28px 20px;
        }

        .navicon {
          background: #000000;
          display: block;
          height: 1px;
          position: relative;
          transition: background 0.2s ease-out;
          width: 18px;

          @media (max-width: 991px) {
            background: transparent;
          }

          @media (max-width: 770px) {
            background: transparent;
          }

          @media (max-width: 767px) {
            background: #bd0c21;
          }
        }
      }

      .user-icon {
        display: flex;
        align-items: center;

        i {
          margin-right: 20px;
          font-size: 20px;
          font-family: "Nunito";
          color: #fff;
        }

        .menu-icon {
          margin-bottom: 0;
          padding: 22px 2px 28px 2px;
          margin-left: 18px;
        }

        .menu-text {
          color: #000000;
          font-size: 10px;
          font-family: "Nunito";
          position: absolute;
          top: 30px;
          left: -2px;
        }
      }


    }

    .header .menu-icon .navicon:before,
    .header .menu-icon .navicon:after {
      background: #000000;
      content: "";
      display: block;
      height: 100%;
      position: absolute;
      transition: all 0.2s ease-out;
      width: 100%;

      @media (max-width: 991px) {
        background: transparent;
      }

      @media (max-width: 770px) {
        background: transparent;
      }

      @media (max-width: 767px) {
        background: #bd0c21;
      }
    }

    .header .menu-icon .navicon:before {
      top: 5px;
    }

    .header .menu-icon .navicon:after {
      top: -5px;
    }

    /* menu btn */
    .header .menu-btn {
      display: none;
    }

    .header .menu-btn:checked~.menu {
      max-height: 430px;
    }

    .header .menu-btn:checked~.menu-icon .navicon {
      background: transparent;
    }

    .header .menu-btn:checked~.menu-icon .navicon:before {
      transform: rotate(-45deg);
    }

    .header .menu-btn:checked~.menu-icon .navicon:after {
      transform: rotate(45deg);
    }

    .header .menu-btn:checked~.menu-icon:not(.steps) .navicon:before,
    .header .menu-btn:checked~.menu-icon:not(.steps) .navicon:after {
      top: 0;
    }

    .iconText {
      margin-left: 5px;
    }

    .service-links {
      align-items: center;

      .nav-link {
        color: white !important;
        font-size: 16px;
        font-family: "Nunito";

        i {
          margin-left: 5px;
          font-size: 15px;
          font-family: "Nunito";
        }
      }
    }

    @media (min-width: 992px) {

      // .header li {
      //   // float: left;
      // }
      // .header li a {
      //   // padding: 23px 30px;
      // }
      .header .menu {
        max-height: none !important;
      }

      .header .menu-icon {
        display: none;
      }
    }

    .header {
      display: flex;

      @media (max-width: 480px) {
        .header {
          padding: 0 6px;
          display: block;
          position: fixed;
          top: 0;
          left: -10px;
          right: 0;
          // background: #00164d;
          background-color: white;
          // background-image: linear-gradient(to right, #2f80ed, #56ccf2);
          border-bottom: 1px solid #bcbcbc;


          a {
            img {
              margin-top: 5px;
            }
          }
        }
      }

      @media (max-width: 770px) {
        padding: 0 0px;
        display: block;
        position: fixed;
        top: 0;
        left: 0;
        right: 0;
        // background: #00164d;
        background-color: white;
        // background-image: linear-gradient(to right, #2f80ed, #56ccf2);
        border-bottom: 1px solid #bcbcbc;

        a {
          img {
            margin-top: 5px;
          }
        }
      }
    }


    @media screen and (min-width: 900px) {
      .header {
        padding: 10px 16px;
        // display: block;
        position: fixed;
        top: 0;
        left: 0;
        right: 0;
        // background: #00164d;
        background-color: white;
        // background-image: linear-gradient(to right, #2f80ed, #56ccf2);
        border-bottom: 1px solid #bcbcbc;


        a {
          img {
            margin-top: 5px;
          }
        }
      }
    }
  }
}

.dashboard-menu {
  max-width: 300px;
}

.menu {
  align-items: center;

  .currency-text {
    .ant-select-selector {
      background-color: transparent !important;
      border: none !important;
      box-shadow: none !important;
    }

    .ant-select-arrow {
      display: flex;
      align-items: center;
    }
  }
}

.curreny-dropdown {
  padding: 20px 0 20px 30px;
}

// .ant-select-dropdown {
//   z-index: 9999999999 !important;
//   width: 10% !important;

//   @media (max-width: 991px)  {
//     width: 20% !important;
//   }
// }

.login-modal {
  width: 800px !important;

  .ant-modal-close-x {
    color: #000 !important;
    font-size: 20px !important;
    font-family: "Nunito";
    line-height: 30px !important;
  }

  .ant-modal-footer {
    display: none;
  }

  .close-modal-icon {
    padding-bottom: 15px;
    font-size: 20px;
    font-family: "Nunito";
    color: #000;
    text-align: end;
  }

  .ant-form-item {
    .ant-form-item-control {
      min-width: 100%;
    }
  }

  .ant-input-affix-wrapper::before {
    display: none;
  }

  .ant-form {
    padding: 10px 10px;

    @media (max-width: 767px) {
      padding: 10;
    }
  }

  .login-text {
    text-align: center;
  }

  .forgot-text {
    font-size: 14px;
    font-family: "Nunito";
    color: #bd0c21;
    text-align: center;
    cursor: pointer;

    &:hover {
      text-decoration: underline;
    }
  }

  .ant-col {
    margin-left: 0 !important;
    //text-align: center;

    button {
      width: 100%;
      font-size: 18px;
      font-family: "Nunito";
      font-weight: bold;
    }
  }

  .login-alternatives {
    display: flex;
    margin: 15px 0;

    .fb-btn {
      width: 50%;
      text-align: center;
      padding: 10px 0;
      box-shadow: 0 0.0625rem 0.25rem 0 rgba(0, 0, 0, 0.2);
      margin-right: 10px;

      p {
        margin-bottom: 0;
      }

      img {
        height: 20px;
        text-align: center;
      }
    }

    .google-btn {
      width: 50%;
      text-align: center;
      padding: 10px 0;
      // box-shadow: 0 0.0625rem 0.25rem 0 rgba(0, 0, 0, 0.2);

      p {
        margin-bottom: 0;
      }

      img {
        height: 20px;
        text-align: center;
      }
    }
  }

  .or-text {
    p {
      font-size: 14px;
      font-family: "Nunito";
      color: #000;
      text-align: center;
    }
  }
}

@media (max-width: 767px) {
  .mob-none {
    display: none !important;
  }

  .company-logo img {
    margin-top: 0% !important;
    height: 50px !important;
  }

  .tab-content ul li {
    display: inline-grid !important;
  }

  .login-register-mob {
    display: none !important;
  }
}

.mobile-user {
  color: #fff;
  font-size: 20px;
  font-family: "Nunito";

  .mobile-user-icon {
    display: flex;
    align-items: center;
    display: none !important;

    @media (max-width: 767px) {
      display: flex !important;
    }

    .anticon {
      vertical-align: middle !important;
      padding: 0 5px;
      margin-right: 5px;
    }
  }
}

.responive-more {
  li {
    a {
      font-size: 16px;
      font-family: "Nunito";
      margin-left: 5px;
      text-decoration: none;
    }
  }
}

.supportvalue {
  display: block;
  text-decoration: none;
  font-size: 16px;
  font-family: "Nunito";
  color: #fff !important;
}

.cre-amount {
  color: red !important;
  font-weight: 600;
}

.cre-amount-1 {
  font-weight: 600;
}

.cre-amount-14 {
  font-weight: 600;
  background-color: #bd0c21;
  color: #fff !important;
}

/*css--added*/
.all-top-menu {
  max-height: 0px;
  display: flex;
  float: right;
  padding-top: 2px;
  padding-bottom: 25px;
}

.all-top-menu li {
  padding: 0px 5px;
  font-size: 15px;
  font-family: "Nunito";
}

.last-mob-bdr .ant-select-selector {
  border: 0px solid #d9d9d9 !important;
}

.cart-nav-top {
  padding-top: 0px !important;
}

.cart-nav-top a {
  color: #000 !important;
}

.menu-bdr-nav .ant-dropdown-menu-item {
  border-bottom: 1px solid #000;

  // padding: 0;
  .ant-dropdown-menu-title-content {
    div {
      &:hover {
        color: #bd0c21;
      }
    }
  }
}

.ant-dropdown-menu {
  padding: 0;
}

.balannce-top-ss {
  padding-top: 4px !important;
}

@media screen and (max-width: 768px) {
  .currency-panel-text {
    position: absolute;
    /* z-index: 10; */
    top: -173px;
    right: 84px;
  }

  .nav-itmes-target {
    padding: 0px 10px 0px 0px;
    // .fa{
    //   padding-right: 5px;
    // }
  }

  #only-mob-user {
    display: block !important;
  }

  .currency-panel-text .ant-select-selector {
    padding: 0px 4px !important;
  }
}

#only-mob-user {
  display: none;
}

.nav-itmes-target {
  display: grid !important;
  text-align: center;

  @media mobile {
    display: flex !important;
  }
}

.delas-taget-flight {
  display: none !important;
}

#delas-taget-flight {
  display: none !important;
}

.border-bottom-1 {
  border-bottom: 1px solid !important;
}

.Mega-Menus {
  .ant-dropdown-menu-item {
    font-size: 13px !important;
    font-family: "Nunito";
  }

  .ant-dropdown-menu-item-group-title {
    color: #bd0c21;
    font-weight: 600;
    font-size: 15px;
    font-family: "Nunito";
  }

  ul.ant-dropdown-menu-item-group-list {
    text-transform: capitalize;
  }
}

.sign-register-css-work {

  // border: 1px solid rgb(255, 255, 255);
  // padding: 3% !important;
  .ant-dropdown-trigger {
    border: 1px solid #1d1d1d;
    padding: 6px 20px;
    border-radius: 25px;
  }
}

.sign-register-css-work:hover {

  // border: 1px solid rgb(255, 255, 255);
  // padding: 3% !important;
  .ant-dropdown-trigger:hover {
    // background-color: rgb(255, 255, 255);
    // padding: 10% !important;
    border-radius: 6px;
    border: 1px solid #bd0c21;
    padding: 5px 20px;
    border-radius: 25px;
    background: #bd0c21;
    color: white !important;
    // font-weight: 600;
  }
}



.top-nav-sec {
  width: 91%;

  @media (max-width: 991px) {
    width: 100%;
  }

  @media (max-width: 767px) {
    width: 100%;
  }
}

.my-acc-dropdown {
  padding-top: 5%;

  @media (max-width: 991px) {
    padding-top: 7%;
  }

  @media (max-width: 767px) {
    padding-top: 0%;
  }
}

.nor-viw {
  @media (max-width: 767px) {
    display: none !important;
  }
}

.mob-viw {
  @media (max-width: 767px) {
    display: block !important;
  }
}

.pcvi-wor-hid {
  display: none;

  @media (max-width: 767px) {
    display: block;
  }
}

.for-currency-border-workcss {


  @media (max-width: 767px) {
    border: 1px solid transparent;
    margin-left: -7%;
  }
}

.mov-viw-agent-head {
  background-color: white;
  position: fixed;
  top: 5.9%;
  width: 100%;
  display: flex;
  z-index: 9;
  justify-content: space-around;
 
}