

.bus-search-wrapper {
  .bus-panel-bg {
    .ant-form-item {
      margin-bottom: 0px !important;

      .ant-select-lg {
        border-right: 1px solid #d6d3d3;

        @media (max-width: 767px) {
          border-right: 0px;
          border-bottom: 1px solid #d6d3d3;
        }
        .ant-select-arrow {
          display: none;
        }

        .ant-select-selector {
          height: 65px !important;
          padding: 14px 11px;
          border-color: transparent !important;
          border: 0px solid !important;
          border-right-width: 0px !important;
          border-radius: 0px;
          color: #bfbfbf;
          border: 1px solid #f6f9fc !important;
          cursor: pointer;

          .ant-select-selection-search-input {
            color: black;
            height: 65px !important;
          }
          .ant-select-selection-placeholder {
            border: 0;
            background: none;
            font-weight: 600;
            font-size: 15px;
            outline: medium none;
            text-overflow: ellipsis;
            overflow: hidden;
            white-space: nowrap;
            color: #000000;
          }

          .ant-select-selection-item {
            border: 0;
            background: none;
            font-weight: 600;
            font-size: 15px;
            outline: medium none;
            text-overflow: ellipsis;
            overflow: hidden;
            white-space: nowrap;
            color: #000;
          }
        }
      }

      .ant-picker-large {
        padding: 11px 11px 6.5px !important;
        height: 65px;
        border: none;
        border-right: 1px solid #d6d3d3 !important;
        border-radius: 0px;
        color: #bfbfbf;
        cursor: pointer;

        @media (max-width: 767px) {
          border-right: 0px;
          border-bottom: 1px solid #d6d3d3;
        }
        .ant-picker-input {
          input {
            color: #000;
            font-weight: 600;
          }
        }
        .ant-picker-input input::placeholder {
          color: black;
        }
      }

      .ant-form-item-explain.ant-form-item-explain-error {
        color: #fff !important;
      }
    }
    .destination {
      .ant-form-item {
        .ant-select-lg {
          .ant-select-selector {
            padding-left: 16px;
            @media (max-width: 767px) {
              padding-left: 11px;
            }
            .ant-select-selection-search {
              padding-left: 5px;
              @media (max-width: 767px) {
                padding-left: 0;
              }
            }
          }
        }
      }
    }
  }
  .bus-btn-pri {
    width: 100%;
    height: 65px;
  }
}
.arrow-bus-inr1 {
  position: absolute;
  bottom: 0;
  top: 30%;
  right: -10px;
  z-index: 20;
  transition: all 0.5s ease 0s;
  cursor: pointer;
  margin-top: 25px;
  @media (max-width: 767px) {
    top: 85%;
  }
}
.arrow-bus-inr1:hover {
  transform: rotate(360deg);
}
.custom-mob-calendar {
  z-index: 999999999;
  .custom-mob-calendar-title {
    display: none;
  }
  @media (max-width: 767px) {
    height: 100%;
    width: 98%;

    margin: 0 auto;
    right: 0px !important;

    left: 0px !important;
    top: 0px !important;
    .custom-mob-calendar-title {
      display: block;
    }

    .ant-picker-panel-container {
      width: 100%;
      .ant-picker-panel {
        width: 85%;
        margin: 0 auto;
        display: block;
      }
      .ant-picker-date-panel,
      .ant-picker-content {
        width: 100%;
      }
    }
  }
}


.search-buses{
  height: 58px !important;
    border-radius: 40px !important;
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    margin-top: 8px;
    width: 256px !important;
    margin-left: 452px;    
    align-items: center;
    @media (max-width: 1024px) {
      margin-left: 320px;
    }
    @media (max-width: 991px) {
      margin-left: 262px;
    }
    @media (max-width: 770px) {
      margin-left: 230px;
    }
}
.bus-mywrap{
  @media(max-width:767px){
    margin-top:220px!important;
  }
}
.search-buses{
  @media (max-width:767px){
    margin-top:0px !important;
  }
}
.my-busess{
  @media (max-width:767px){
    margin-bottom:40px !important;
  }
}